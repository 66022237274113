import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.3681303003746!2d17.19171031574989!3d44.77329978729686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475e031af73b0895%3A0x63bcb338a29dcdb8!2sSime%20%C5%A0olaje%201a%2C%20Banja%20Luka%2078000!5e0!3m2!1shr!2sba!4v1669156610571!5m2!1shr!2sba"
                width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>
        </>
    )
}



export default GoogleMap;  